<template>
    <div class="browse-memos">
        <div class="container">
            <memod-list list-endpoint="/feeds?type=for-you" />
        </div>
    </div>
</template>

<script>
export default {
    name: "BrowseMemos",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    }
}
</script>
